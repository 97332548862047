<template>
    <div class="share">
        <img class="w100" src="@/assets/images/image-1.png" alt="" />
        <img class="w691" src="@/assets/images/image-2.png" alt="" />
        <a href="tel:89860000">
            <img class="w691" style="margin-top:26px" src="@/assets/images/image-3.png" alt="" />
        </a>
        <img @click="toLink" class="w691" src="@/assets/images/image-4.png" alt="" />
    </div>
</template>

<script>
export default {
    methods: {
        toLink() {
            this.$router.push('/')
        }
    }
}
</script>

<style lang="scss" scoped>
.share {
    background-color: #444ce2;
    text-align: center;
    .w100 {
        width: 100%;
    }
    .w691 {
        width: 691px;
    }
}
</style>
