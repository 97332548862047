import request from '@/utils/request'

// 司机申请注册
export function applyForRegistration(data) {
    return request({
        url: '/driving/wap/driver/apply_for_registration',
        method: 'post',
        data
    })
}

export function upgradeDetection(data) {
    return request({
        url: '/driving/app/upgrade_detection',
        method: 'get',
        params: data
    })
}
