import request from '@/utils/request'

// 上传文件
export function uploadFile(query) {
    return request({
        url: '/msg/complaint/uploadFile',
        method: 'post',
        data: query
    })
}
