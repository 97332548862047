import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    baseURL: '/api',
    withCredentials: true,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (getToken()) {
            config.headers['x-auth-token'] = getToken()
        }

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        if ('x-auth-token' in response.headers) {
            setToken(response.headers['x-auth-token'])
        }
        const res = response.data
        if (res.code) {
            // if the custom code is not 20000, it is judged as an error.
            if (res.code !== 200) {
                if (res.code === 1001 || res.code === 1003) {
                    removeToken()
                    sessionStorage.removeItem('daijia')
                    sessionStorage.removeItem('login')
                    window.location.replace('/')
                }
                return Promise.reject(new Error(res.message || 'Error'))
            } else {
                return res
            }
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service
