<template>
    <div class="login">
        <img class="header-image" src="@/assets/images/header.png" alt="" />
        <van-form class="form" @submit="onSubmit">
            <van-field
                v-model="username"
                name="username"
                label="用户名"
                placeholder="请输入2~4位中文"
                maxlength="4"
                :rules="[{ required: true, validator: name, message: '请输入正确的姓名' }]"
            />
            <van-field
                v-model="tel"
                type="tel"
                name="phone"
                label="手机号码"
                placeholder="手机号码"
                maxlength="11"
                :rules="[{ required: true, validator: phone, message: '请输入正确的手机号码' }]"
            />
            <van-field
                v-model="idCard"
                name="idCard"
                label="身份证号码"
                placeholder="请输入身份证号码"
                maxlength="18"
                :rules="[{ required: true, validator: isidCard, message: '请输入正确的身份证号码' }]"
            />
            <!-- <van-field
                name="uploader"
                label="请上传身份证正面(国徽面)"
                :rules="[{ required: true, message: '请上传身份证正面' }]"
            >
                <template #input>
                    <van-uploader v-model="fileList1" :after-read="afterRead1" :max-count="1" />
                </template>
            </van-field>
            <van-field
                name="uploader"
                label="请上传身份证反面(人像面)"
                :rules="[{ required: true, message: '请上传身份证反面' }]"
            >
                <template #input>
                    <van-uploader v-model="fileList2" :after-read="afterRead2" :max-count="1" />
                </template>
            </van-field> -->
            <van-field
                v-model="value2"
                name="validator"
                label="请输入密码"
                placeholder="密码长度6位英文或数字"
                maxlength="6"
                :rules="[{ required: true, validator: password, message: '请输入正确的密码' }]"
            />
            <van-field
                v-model="value3"
                name="validator"
                label="再次输入密码"
                placeholder="再次输入密码"
                maxlength="6"
                :rules="[{ validator: validator, message: '两次密码不一致' }]"
            />

            <van-button class="btn" round block type="info" native-type="submit" :disabled="disable">提 交</van-button>
        </van-form>
    </div>
</template>

<script>
import Vue from 'vue'
import { Form } from 'vant'
import { Field } from 'vant'
import { Button } from 'vant'
import { Toast } from 'vant'
import { Uploader } from 'vant'

Vue.use(Uploader)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
import { applyForRegistration } from '@/api/driverRegistration'
import { uploadFile } from '@/api/msg'
import md5 from '@/utils/md5'
import { isvalidPhone, isZh, isEngNum, isidCard } from '@/utils/validate'
export default {
    name: 'Login',
    components: {},

    data() {
        return {
            username: '',
            tel: '',
            disable: false,
            value2: '',
            value3: '',
            idCard: '',
            id_card_front: '',
            id_card_back: '',
            passwordMD5: md5,
            fileList1: [],
            fileList2: []
        }
    },
    created() {},
    mounted() {},
    methods: {
        phone(val) {
            return isvalidPhone(val)
        },
        name(val) {
            if (val.length < 2) {
                return false
            }
            return isZh(val)
        },
        password(val) {
            if (val.length < 6) {
                return false
            }
            return isEngNum(val)
        },
        validator(val) {
            return this.value2 == val
        },
        isidCard(val) {
            console.log(val, isidCard(val))
            return isidCard(val)
        },
        afterRead1(file) {
            file.status = 'uploading'
            file.message = '上传中...'

            var fileId = file.file
            let formdata = new FormData()
            formdata.append('file', fileId, fileId.name)
            uploadFile(formdata)
                .then(res => {
                    file.status = 'done'
                    this.id_card_front = res
                })
                .catch(err => {
                    file.status = 'failed'
                    file.message = err
                })
        },
        afterRead2(file) {
            file.status = 'uploading'
            file.message = '上传中...'

            var fileId = file.file
            let formdata = new FormData()
            formdata.append('file', fileId, fileId.name)
            uploadFile(formdata)
                .then(res => {
                    file.status = 'done'
                    this.id_card_back = res
                })
                .catch(err => {
                    file.status = 'failed'
                    file.message = err
                })
        },
        onSubmit() {
            this.disable = true
            applyForRegistration({
                driverName: this.username,
                driverPhone: this.tel,
                wxOpenId: JSON.parse(sessionStorage.getItem('daijia')).openId,
                driverPassword: this.passwordMD5(this.value2),
                idCard: this.idCard
            })
                .then(() => {
                    Toast('申请成功，请等待审核。')
                    this.$router.push('/success')
                })
                .catch(err => {
                    this.disable = false
                    Toast(err.message)
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.login ::v-deep {
    .van-cell::after {
        border: none;
    }
    .van-field__label,
    .van-field__value {
        height: 84px;
        line-height: 84px;
    }
    .van-field__value {
        padding-left: 28px;
        background: #f5f5f5;
        border-radius: 41px;
    }
}
.login {
    height: 100vh;

    background-color: #444ce2;
    .header-image {
        width: 100%;
    }
    .form {
        width: 690px;
        height: 800px;
        margin: 0 auto;
        padding-top: 74px;
        padding-bottom: 116px;
        background: #ffffff;
        box-shadow: 1px 7px 18px 0px rgba(37, 44, 184, 0.78);
        border-radius: 32px;
    }
    .btn {
        margin: 50px auto 0 auto;
        width: 618px;
        height: 97px;
        background: linear-gradient(-3deg, #48adf5, #3374ef);
        border-radius: 48px;
        font-weight: bold;
    }
}
</style>
