<template>
    <div class="success">
        <img class="success-image" src="@/assets/images/download-txt.png" alt="" />
        <div class="btn" @click="returnBrowserEnvFlag">立 即 下 载</div>
        <!-- <div id="no-down">微信/QQ内无法下载应用</div> -->
        <div id="alert">
            <div id="triangle-up"></div>
            <div>请点击右上角</div>
            <div>请选择“在浏览器打开”</div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)
import { upgradeDetection } from '@/api/driverRegistration'
export default {
    methods: {
        returnBrowserEnvFlag() {
            let browserEnvType = ''
            var ua = navigator.userAgent.toLowerCase()
            console.log(ua)
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                browserEnvType = 'wx'
            } else if (ua.match(/Alipay/i) == 'alipay') {
                browserEnvType = 'zfb'
            } else {
                browserEnvType = 'browser'
            }
            if (browserEnvType == 'wx' || browserEnvType == 'zfb') {
                document.getElementById('alert').style.display = 'block'
                // document.getElementById('no-down').style.display = 'block'
                // document.getElementById('btn').style.display = 'none'
                // document.getElementById('version').style.display = 'none'
                return
            }
            if (browserEnvType == 'browser') {
                // let isAndroid = ua.indexOf('android') > -1 || ua.indexOf('linux') > -1 //g
                let isIOS = !!ua.match(/\(i[^;]+;( U;)? cpu.+mac os x/) //ios终端
                // console.log("2222222222222222", isIOS);
                // if (isAndroid) {
                //     //这个是安卓操作系统
                //     // console.log("isAndroid");
                //     upgradeDetection({ version: '1.0.0' }).then(res => {
                //         window.location.href = res.data.downloadUrl
                //     })
                // }
                if (isIOS) {
                    // console.log("ios");
                    //这个是ios操作系统
                    // window.location.href =
                    //     'https://itunes.apple.com/cn/app/%E5%B0%8F%E6%A1%A9%E5%81%9C%E8%BD%A6/id1323927569?mt=8'
                    window.location.href = 'https://apps.apple.com/cn/app/id6450769169'
                } else {
                    upgradeDetection({ version: '1.0.0', companyId: '1' }).then(res => {
                        window.location.href = res.data.downloadUrl
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.success {
    height: 100vh;
    background-color: #444ce2;
    text-align: center;
    .success-image {
        width: 100%;
    }
    .box {
        width: 644px;
        margin: 0 auto;
        padding: 30px;
        border: 25px solid #ffb35d;
        background-color: #ffd37a;
        div {
            text-align: center;
            color: #643b3b;
        }
    }
    .download-image {
        margin-top: 30px;
        width: 413px;
    }
    .btn {
        width: 618px;
        height: 97px;
        margin: 0 auto;
        background: linear-gradient(-3deg, #48adf5, #3374ef);
        border-radius: 48px;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 97px;
    }
    #alert {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        background: #ff8c00;
        color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
    }

    #triangle-up {
        position: absolute;
        top: -10px;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 10px solid #ff8c00;
    }

    #no-down {
        display: none;
    }
}
</style>
