/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
    const valid_map = ['djadmin']
    return valid_map.indexOf(str.trim()) >= 0
}
// 手机号验证
export function isvalidPhone(str) {
    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
    return reg.test(str)
}
/**
 * 中文
 * @param {string} str
 * @returns {Boolean}
 */
export function isZh(str) {
    const reg = /[\u4e00-\u9fa5]/
    return reg.test(str)
}
/**
 * 英文和数字
 * @param {string} str
 * @returns {Boolean}
 */
export function isEngNum(str) {
    const reg = /^[A-Za-z0-9]+$/
    return reg.test(str)
}
/**
 * 判断身份证号
 * @param {string} str
 * @returns {Boolean}
 */
export function isidCard(str) {
    const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    return reg.test(str)
}
